var render = function render(){
  var _vm$selectedIntegrati, _vm$selectedSource, _vm$formValues, _vm$formValues$projec;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.selectedChannel ? "Update" : "Add") + " " + _vm._s(_vm.titleMap[(_vm$selectedIntegrati = _vm.selectedIntegration) === null || _vm$selectedIntegrati === void 0 ? void 0 : _vm$selectedIntegrati.name]) + " ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "bodyFont semi-bold",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('div', [_vm._v(" " + _vm._s((_vm$selectedSource = _vm.selectedSource) === null || _vm$selectedSource === void 0 ? void 0 : _vm$selectedSource.name) + " ")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "filled": "",
      "items": _vm.projectList,
      "item-text": "name",
      "return-object": ""
    },
    model: {
      value: _vm.formValues.project,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "project", $$v);
      },
      expression: "formValues.project"
    }
  }, 'v-select', _vm.veeValidate('Project', "Project"), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "filled": "",
      "disabled": !_vm.formValues.project,
      "items": (_vm$formValues = _vm.formValues) === null || _vm$formValues === void 0 ? void 0 : (_vm$formValues$projec = _vm$formValues.project) === null || _vm$formValues$projec === void 0 ? void 0 : _vm$formValues$projec.issueTypes,
      "item-value": "id",
      "item-text": "name",
      "return-object": ""
    },
    model: {
      value: _vm.formValues.info.issue,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues.info, "issue", $$v);
      },
      expression: "formValues.info.issue"
    }
  }, 'v-select', _vm.veeValidate('issue', 'Issue type'), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-autocomplete', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "items": _vm.assigneList,
      "loading": _vm.assigneeLoading,
      "search-input": _vm.assigneeSearch,
      "label": "Select assignee",
      "dense": "",
      "filled": "",
      "item-text": "emailAddress",
      "item-value": "id",
      "return-object": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.assigneeSearch = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.assigneeSearch = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "smallFont"
        }, [_c('div', [_vm._v(" " + _vm._s(item.emailAddress || item.displayName) + " ")])])];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-list-item', {
          on: {
            "click": function click($event) {
              _vm.formValues.info.assignee = item;
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.displayName))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.emailAddress))])], 1)], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [!_vm.assigneeSearch ? _c('v-list-item', [_vm._v(" Type user email ")]) : !_vm.assigneList ? _c('v-list-item', [_vm._v(" Hit search")]) : _vm.assigneList ? _c('v-list-item', [_vm._v(" No email found")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formValues.info.assignee,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues.info, "assignee", $$v);
      },
      expression: "formValues.info.assignee"
    }
  }, 'v-autocomplete', _vm.veeValidate('assignee', 'Assignee'), false))], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.assigneeLoading,
      "color": "primary",
      "outlined": "",
      "disabled": !_vm.assigneeSearch
    },
    on: {
      "click": function click($event) {
        return _vm.getUserList('assignee');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v(" Search ")], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-autocomplete', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "items": _vm.reporterList,
      "loading": _vm.reporterLoading,
      "search-input": _vm.reporterSearch,
      "dense": "",
      "label": "Select reporter",
      "filled": "",
      "item-text": "emailAddress",
      "item-value": "id",
      "return-object": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.reporterSearch = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.reporterSearch = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('div', [_vm._v(" " + _vm._s(item.emailAddress) + " ")])])];
      }
    }, {
      key: "item",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-list-item', {
          on: {
            "click": function click($event) {
              _vm.formValues.info.reporter = item;
            }
          }
        }, [_vm._v(" " + _vm._s(item.emailAddress || item.displayName) + " ")])];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [!_vm.reporterSearch ? _c('v-list-item', [_vm._v(" Type user email ")]) : !_vm.reporterList ? _c('v-list-item', [_vm._v(" Hit search")]) : _vm.reporterList ? _c('v-list-item', [_vm._v(" No email found")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formValues.info.reporter,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues.info, "reporter", $$v);
      },
      expression: "formValues.info.reporter"
    }
  }, 'v-autocomplete', _vm.veeValidate('reporter', 'Reporter'), false))], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.reporterLoading,
      "color": "primary",
      "disabled": !_vm.reporterSearch,
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.getUserList('reporter');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v(" Search ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": _vm.busy
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.busy
    },
    on: {
      "click": _vm.confirmSubmit
    }
  }, [_vm.fromWorkspace ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v(" " + _vm._s(_vm.selectedChannel ? "Update" : "Add") + " ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }