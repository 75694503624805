var render = function render(){
  var _vm$selectedIntegrati, _vm$selectedSource, _vm$selectedIntegrati2, _vm$selectedIntegrati3, _vm$selectedIntegrati4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.selectedChannel ? "Update" : "Add") + " " + _vm._s(_vm.titleMap[(_vm$selectedIntegrati = _vm.selectedIntegration) === null || _vm$selectedIntegrati === void 0 ? void 0 : _vm$selectedIntegrati.name]) + " ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "bodyFont semi-bold",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('div', [_vm._v(" " + _vm._s((_vm$selectedSource = _vm.selectedSource) === null || _vm$selectedSource === void 0 ? void 0 : _vm$selectedSource.name) + " ")])])]), ['Microsoft Teams', 'Slack'].includes(_vm.selectedIntegration.name) ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "pointer-cursor",
    on: {
      "click": function click($event) {
        return _vm.showZoomedImage('microsoftTeam');
      }
    }
  }, [_vm.selectedIntegration.name === 'Microsoft Teams' ? _c('v-carousel', {
    attrs: {
      "height": "250",
      "progress": "primary",
      "hide-delimiters": ""
    }
  }, _vm._l(4, function (i) {
    return _c('v-carousel-item', {
      key: i,
      attrs: {
        "src": "/media/integrations/microsoft_teams/".concat(i + 1, ".png"),
        "contain": "",
        "height": "250"
      }
    });
  }), 1) : _vm._e()], 1), _c('div', {
    staticClass: "pointer-cursor",
    on: {
      "click": function click($event) {
        return _vm.showZoomedImage('slack');
      }
    }
  }, [_vm.selectedIntegration.name === 'Slack' ? _c('v-carousel', {
    attrs: {
      "height": "250",
      "progress": "primary",
      "hide-delimiters": ""
    }
  }, _vm._l(4, function (i) {
    return _c('v-carousel-item', {
      key: i,
      attrs: {
        "src": "/media/integrations/slack/".concat(i, ".png"),
        "contain": "",
        "height": "250"
      }
    });
  }), 1) : _vm._e()], 1)]) : _vm._e(), _vm.selectedIntegration.name !== 'Microsoft Teams' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "filled": ""
    },
    model: {
      value: _vm.formValues.uid,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "uid", $$v);
      },
      expression: "formValues.uid"
    }
  }, 'v-text-field', _vm.veeValidate('Channel ID', "".concat(_vm.titleMap[(_vm$selectedIntegrati2 = _vm.selectedIntegration) === null || _vm$selectedIntegrati2 === void 0 ? void 0 : _vm$selectedIntegrati2.name], " ID")), false))], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "filled": ""
    },
    model: {
      value: _vm.formValues.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "name", $$v);
      },
      expression: "formValues.name"
    }
  }, 'v-text-field', _vm.veeValidate('Channel Name', "".concat(_vm.titleMap[(_vm$selectedIntegrati3 = _vm.selectedIntegration) === null || _vm$selectedIntegrati3 === void 0 ? void 0 : _vm$selectedIntegrati3.name], " name")), false))], 1), ((_vm$selectedIntegrati4 = _vm.selectedIntegration) === null || _vm$selectedIntegrati4 === void 0 ? void 0 : _vm$selectedIntegrati4.name) === 'Microsoft Teams' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|url',
      expression: "'required|url'"
    }],
    attrs: {
      "filled": ""
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function fn() {
        return [_c('v-icon', {
          on: {
            "click": function click($event) {
              _vm.showWebhookInfoModal = true;
            }
          }
        }, [_vm._v("mdi-help-circle-outline")])];
      },
      proxy: true
    }], null, false, 3432130014),
    model: {
      value: _vm.microsoftTeamWebhook,
      callback: function callback($$v) {
        _vm.microsoftTeamWebhook = $$v;
      },
      expression: "microsoftTeamWebhook"
    }
  }, 'v-text-field', _vm.veeValidate('Url', 'Channel Incoming Webhook URL'), false))], 1) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": _vm.busy
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.busy
    },
    on: {
      "click": _vm.confirmSubmit
    }
  }, [_vm.fromWorkspace ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v(" " + _vm._s(_vm.selectedChannel ? "Update" : "Add") + " ")])])], 1), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', {
          staticClass: "pa-4"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Follow these steps to connect your channel with your team: "), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("From your channel menu, open "), _c('b', [_vm._v("Connectors")])]), _c('li', [_vm._v(" Search for "), _c('b', [_vm._v("Incoming Webhook")]), _vm._v(" and click "), _c('b', [_vm._v("Configure")])]), _c('li', [_vm._v("Name of Webhook: "), _c('b', [_vm._v("TestApp.io")])]), _c('li', [_vm._v(" Image: "), _c('a', {
          staticClass: "text-decoration-underline",
          attrs: {
            "href": "https://assets.testapp.io/logo/icon/512.png",
            "target": "_blank"
          }
        }, [_c('b', [_vm._v("Click here to download")])])]), _c('li', [_vm._v("Click on "), _c('b', [_vm._v("Create")]), _vm._v(" and copy the "), _c('b', [_vm._v("URL")])])]), _c('br'), _vm._v(" You can learn more from "), _c('a', {
          attrs: {
            "href": "https://blog.testapp.io/connect-microsoft-teams/",
            "target": "_blank"
          }
        }, [_vm._v(" this article")])])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showWebhookInfoModal,
      callback: function callback($$v) {
        _vm.showWebhookInfoModal = $$v;
      },
      expression: "showWebhookInfoModal"
    }
  }), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', {
          staticClass: "pa-3"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm.selectedImage === 'microsoftTeam' ? _c('v-carousel', {
          attrs: {
            "height": "250",
            "hide-delimiters": ""
          }
        }, _vm._l(4, function (i) {
          return _c('v-carousel-item', {
            key: i,
            attrs: {
              "width": "100%",
              "height": "100%",
              "contain": "",
              "src": "/media/integrations/microsoft_teams/".concat(i + 1, ".png")
            }
          });
        }), 1) : _vm._e(), _vm.selectedImage === 'slack' ? _c('v-carousel', {
          attrs: {
            "hide-delimiters": ""
          }
        }, _vm._l(2, function (i) {
          return _c('v-carousel-item', {
            key: i,
            attrs: {
              "contain": "",
              "height": "100%",
              "width": "100%",
              "src": "/media/integrations/slack/".concat(i, ".png")
            }
          });
        }), 1) : _vm._e()], 1)], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showZoomedImageModal,
      callback: function callback($$v) {
        _vm.showZoomedImageModal = $$v;
      },
      expression: "showZoomedImageModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }