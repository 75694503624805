<template>
  <v-card class="pa-3">
    <v-card-title>
      {{ selectedSource ? "Update" : "Connect with" }} Microsoft Teams
    </v-card-title>
    <v-card-text>
      <v-row class="font-16">
        <v-col @click="enlargeImage = true" cols="12">
          <v-img
            height="300"
            src="/media/integrations/microsoft_teams/1.png"
            contain
          ></v-img>
        </v-col>
        <v-col v-if="!selectedSource" cols="12">
          <v-text-field
            filled
            v-model="name"
            v-validate="'required'"
            v-bind="veeValidate('name', 'Team name')"
          ></v-text-field>
        </v-col>
        <!-- <v-col v-if="!selectedSource" cols="12">
          <v-text-field
            filled
            v-model="channel"
            v-validate="'required'"
            v-bind="veeValidate('channel', 'Enter channel name')"
          ></v-text-field>
        </v-col> -->
      </v-row>
    </v-card-text>
    <v-card-actions>
      <!-- <v-btn
        v-if="selectedIntegration?.status === 1"
        :loading="loading"
        color="error"
        depressed
        :outlined="$vuetify.theme.dark"
        class="text-transform-none"
        @click="confirmDisable"
      >
        Disable
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn
        :disabled="loading"
        @click="$emit('close')"
        class="text-transform-none"
        text
        color="primary"
      >
        Cancel
      </v-btn>
      <v-btn
        :loading="loading"
        color="primary"
        depressed
        :disabled="errors.any()"
        :outlined="$vuetify.theme.dark"
        class="text-transform-none"
        @click="confirmRequest"
      >
        Connect
      </v-btn>
    </v-card-actions>
    <Modal closeable v-model="enlargeImage">
      <template #card>
        <v-card v-if="enlargeImage" class="pa-3">
          <v-row>
            <v-col cols="12">
              <v-img src="/media/integrations/microsoft_teams/1.png"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </Modal>
  </v-card>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import {
  ENABLE_APP_MICROSOFT_TEAM,
  DISABLE_APP_MICROSOFT_TEAM,
  DISABLE_MICROSOFT_TEAM,
} from "@/store/team/integrations.module.js";
import { isEmpty } from "@/core/services/helper.service";
export default {
  mixins: [veeValidate],
  props: {
    selectedIntegration: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedSource: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isEmpty,
      enableTeamForAllApps: false,
      enlargeImage: false,
      showWebhookInfoModal: false,
      name: "",
      channel: "",
      microsoftTeamWebhook: "",
      loading: false,
    };
  },
  created() {
    if (!isEmpty(this.selectedSource)) {
      this.microsoftTeamWebhook = this.selectedSource?.info?.webhook_url;
    }
  },
  methods: {
    confirmRequest() {
      if (this.selectedSource) {
        this.updateMicrosoftTeam();
      } else {
        this.enableMicrosoftTeam();
      }
    },
    confirmDisable() {
      if (this.$route.params.app_id) {
        this.disableAppMicrosoftTeams();
      } else {
        this.disableMicrosoftTeam();
      }
    },
    updateMicrosoftTeam() {
      if (this.loading) return;
      this.loading = true;
      const dataToPost = {
        webhook_url: this.microsoftTeamWebhook,
      };
      this.$portalApi
        .put(
          this.apiRoutes.portal.integrations.source.update(
            this.$route.params.integration_id,
            this.selectedSource.id
          ),
          dataToPost
        )
        .then(({ data }) => {
          this.loading = false;
          this.notifyUserMessage({ message: data.message });
          this.$emit("success");
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    async enableMicrosoftTeam() {
      if (await this.validateAllFields()) {
        if (this.loading) return;
        this.loading = true;
        const dataToPost = {
          name: this.name,
          channel: this.channel,
          webhook_url: this.microsoftTeamWebhook,
        };
        this.$portalApi
          .post(
            this.apiRoutes?.portal?.integrations?.source?.connect.microsoftTeam(
              this.$route.params.integration_id
            ),
            dataToPost
          )
          .then((response) => {
            this.notifyUserMessage({ message: response?.data?.message });
            this.$emit("success");
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            if (err && err.status === 402) {
              this.$emit("open-subscription", err.message);
            } else {
              this.notifyErrorMessage(err.message);
            }
          });
      }
    },
    enableAppMicrosoftTeam() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(ENABLE_APP_MICROSOFT_TEAM, {
          app_id: this.$route.params.app_id,
          webhook_url: this.microsoftTeamWebhook,
        })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.$emit("success");
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err && err.status === 402) {
            this.$emit("open-subscription", err.message);
          } else {
            this.notifyErrorMessage(err.message);
          }
        });
    },
    disableMicrosoftTeam() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DISABLE_MICROSOFT_TEAM)
        .then((response) => {
          this.loading = false;
          this.$emit("disable-success", "from-team");
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    disableAppMicrosoftTeams() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DISABLE_APP_MICROSOFT_TEAM, {
          app_id: this.$route.params.app_id,
        })
        .then((response) => {
          this.loading = false;
          this.$emit("disable-success", "from-app");
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>
