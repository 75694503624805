<template>
  <v-card>
    <v-card-title> JIRA Integration </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row align="center">
            <v-col cols="11">
              <v-text-field
                filled
                v-model="formValues.server_url"
                v-validate="'required|url'"
                v-bind="veeValidate('Url', 'JIRA server url')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" class="pl-0">
              <v-btn icon color="primary">
                <v-icon @click="initiateJiraFieldInformation('server')"
                  >mdi-help-circle-outline</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <v-col cols="11">
              <v-text-field
                filled
                v-model="formValues.username"
                v-validate="'required'"
                v-bind="veeValidate('username', 'Username')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" class="pl-0">
              <v-btn icon color="primary">
                <v-icon @click="initiateJiraFieldInformation('username')"
                  >mdi-help-circle-outline</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <v-col cols="11">
              <v-text-field
                filled
                v-model="formValues.api_token"
                v-validate="'required'"
                v-bind="veeValidate('api token', 'API Token')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" class="pl-0">
              <v-btn icon color="primary">
                <v-icon @click="initiateJiraFieldInformation('api-token')"
                  >mdi-help-circle-outline</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="$emit('close')"
        text
        color="primary"
        :disabled="loading"
        class="text-transform-none"
      >
        Cancel
      </v-btn>
      <v-btn
        :outlined="$vuetify.theme.dark"
        color="primary"
        :loading="loading"
        class="text-transform-none"
        @click="confirmConnectJira"
      >
        Connect
      </v-btn>
    </v-card-actions>
    <Modal closeable v-model="showWebhookInfoModal">
      <template #card>
        <v-card class="pa-6">
          <v-row>
            <v-col v-if="infoType === 'server'" cols="12">
              <div class="titleFont my-2 semi-bold">JIRA Server URL:</div>
              <ul class="bodyFont">
                <li>
                  The JIRA Server URL is the web address you use to access your
                  JIRA instance.
                </li>
                <li>
                  How to Find:
                  <ul>
                    <li>Log into your JIRA account.</li>
                    <li>
                      The URL in your browser's address bar when you are on the
                      dashboard is your JIRA Server URL.
                    </li>
                    <li>
                      It typically looks like
                      <code> https://yourcompany.atlassian.net.</code>
                    </li>
                  </ul>
                </li>
              </ul>
            </v-col>
            <v-col v-if="infoType === 'username'" cols="12">
              <div class="titleFont my-2 semi-bold">JIRA Username:</div>
              <ul class="bodyFont">
                <li>
                  This is the username you use to log into your JIRA account.
                </li>
                <li>
                  How to Find:
                  <ul>
                    <li>
                      If you are unsure of your username, you can find it in the
                      account settings of your JIRA profile.
                    </li>
                    <li>
                      Go to the top-right corner of the JIRA dashboard, click on
                      your profile icon, and select 'Account settings' or
                      'Profile'.
                    </li>
                    <li>Your username is usually displayed on this page.</li>
                  </ul>
                </li>
              </ul>
            </v-col>
            <v-col v-if="infoType === 'api-token'" cols="12">
              <div class="titleFont my-2 semi-bold">JIRA API Token:</div>
              <ul class="bodyFont">
                <li>
                  An API token is used for secure authentication when
                  integrating with JIRA APIs.
                </li>
                <li>
                  How to create:
                  <ul>
                    <li>
                      Visit the Atlassian Account's API Token page:
                      <a
                        href=" https://id.atlassian.com/manage-profile/security/api-tokens"
                        target="_blank"
                      >
                        https://id.atlassian.com/manage-profile/security/api-tokens</a
                      >.
                    </li>
                    <li>Log in with your JIRA account credentials.</li>
                    <li>Click on ‘Create API token’.</li>
                    <li>
                      In the dialog that appears, provide a label for your token
                      (e.g., 'Integration with XYZ').
                    </li>
                    <li>Click ‘Create’ to generate the token.</li>
                    <li>
                      Copy the token to a secure place. It won’t be shown again.
                    </li>
                  </ul>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </Modal>
  </v-card>
</template>
<script>
import veeValidate from "@/mixins/veeValidate";
export default {
  mixins: [veeValidate],
  props: {
    selectedSource: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      showWebhookInfoModal: false,
      infoType: "",
      loading: false,
      formValues: {
        server_url: "",
        username: "",
        api_token: "",
      },
    };
  },
  created() {
    if (this.selectedSource?.info) {
      this.formValues = { ...this.selectedSource?.info };
    }
  },
  methods: {
    confirmConnectJira() {
      this.connectJira();
    },
    async connectJira() {
      if (await this.validateAllFields()) {
        if (this.loading) return;
        this.loading = true;
        this.$portalApi
          .post(
            this.apiRoutes.portal.integrations.source.connect.jira(
              this.$route.params.integration_id
            ),
            this.formValues
          )
          .then(({ data }) => {
            this.loading = false;
            this.notifyUserMessage({ message: data.message });
            this.$emit("success");
          })
          .catch((err) => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    updateJiraConfiguration() {
      if (this.loading) return;
      this.loading = true;
      this.$portalApi
        .put(
          this.apiRoutes.portal.integrations.source.update(
            this.$route.params.integration_id,
            this.selectedSource.id
          ),
          this.formValues
        )
        .then(({ data }) => {
          this.loading = false;
          this.notifyUserMessage({ message: data.message });
          this.$emit("success");
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    initiateJiraFieldInformation(type) {
      this.infoType = type;
      this.showWebhookInfoModal = true;
    },
  },
};
</script>
