var render = function render(){
  var _vm$selectedIntegrati, _vm$selectedIntegrati2, _vm$selectedIntegrati3, _vm$selectedIntegrati4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [!_vm.sourceList.length ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "depressed": "",
      "color": "primary",
      "loading": _vm.connectSourceLoading,
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.confirmConnectSource
    }
  }, [_vm._v(" Connect " + _vm._s(_vm.titleMap[(_vm$selectedIntegrati = _vm.selectedIntegration) === null || _vm$selectedIntegrati === void 0 ? void 0 : _vm$selectedIntegrati.name]))]) : _vm._e()], 1), _vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', _vm._l(3, function (i) {
    return _c('v-col', {
      key: i
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "card, list"
      }
    })], 1);
  }), 1)], 1) : !_vm.sourceList.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "Connect your first  ".concat(_vm.titleMap[(_vm$selectedIntegrati2 = _vm.selectedIntegration) === null || _vm$selectedIntegrati2 === void 0 ? void 0 : _vm$selectedIntegrati2.name])
    }
  })], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "darkGrey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "semi-bold bodyFont"
  }, [_vm._v(" " + _vm._s(_vm.titleMap[(_vm$selectedIntegrati3 = _vm.selectedIntegration) === null || _vm$selectedIntegrati3 === void 0 ? void 0 : _vm$selectedIntegrati3.name]) + "s ")]), _c('v-col', {
    staticClass: "text-right"
  }, [_vm.canManageTeam ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "depressed": "",
      "color": "primary",
      "loading": _vm.connectSourceLoading || _vm.slackLoading,
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.confirmConnectSource
    }
  }, [_vm._v(" Connect " + _vm._s(_vm.titleMap[(_vm$selectedIntegrati4 = _vm.selectedIntegration) === null || _vm$selectedIntegrati4 === void 0 ? void 0 : _vm$selectedIntegrati4.name]))]) : _vm._e()], 1)], 1)], 1), _vm._l(_vm.sourceList, function (item, index) {
    var _vm$selectedSource;
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      class: item.id === ((_vm$selectedSource = _vm.selectedSource) === null || _vm$selectedSource === void 0 ? void 0 : _vm$selectedSource.id) ? 'activeCard' : '',
      attrs: {
        "outlined": "",
        "flat": ""
      },
      on: {
        "click": function click($event) {
          _vm.selectedSource = item;
        }
      }
    }, [_c('v-card-title', [_c('div', [_c('div', {
      staticClass: "semi-bold"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "smallFont"
    }, [_vm._v(_vm._s(item.uid))])]), _c('v-spacer'), _vm.canManageTeam ? _c('action-dropdown', {
      attrs: {
        "id": "membersActionDropdown"
      },
      scopedSlots: _vm._u([{
        key: "list",
        fn: function fn() {
          return [_c('v-list-item', {
            on: {
              "click": function click($event) {
                return _vm.addChannelInfo(item);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-plus")]), _vm._v(" Add Channel ")], 1), _c('v-list-item', {
            on: {
              "click": function click($event) {
                return _vm.removeIntegraion(item);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("cancel")]), _vm._v(" Disconnect ")], 1)];
        },
        proxy: true
      }], null, true)
    }) : _vm._e()], 1), _c('v-card-text', [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', [_c('user-avatar', {
      attrs: {
        "user-detail": item.user
      }
    })], 1)]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', [_c('vue-hoverable-date', {
      attrs: {
        "date": item.created_at
      }
    })], 1)])], 1)], 1)], 1)], 1);
  })], 2)], 1), _c('v-col', {
    staticClass: "pt-15",
    attrs: {
      "cols": "9"
    }
  }, [_vm.selectedSource ? _c('integration-sub-source', {
    key: _vm.selectedSource.id,
    attrs: {
      "selected-integration": _vm.selectedIntegration,
      "selected-source": _vm.selectedSource
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showCreateChannelForm ? _c('add-slack-channel-form', {
          attrs: {
            "selected-source": _vm.selectedSource
          },
          on: {
            "close": function close($event) {
              _vm.showCreateChannelForm = false;
            },
            "sucess": _vm.afterChannelSuccess
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showCreateChannelForm,
      callback: function callback($$v) {
        _vm.showCreateChannelForm = $$v;
      },
      expression: "showCreateChannelForm"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.popupDisabledDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "text-left text-h5 mt-4",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Oops! Seems like you have disabled popups for this site. ")]), _c('v-col', {
          staticClass: "text-left text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Follow below steps to enable popups for this site. "), _c('ul', [_c('li', [_vm._v("Go to your browser settings")]), _c('li', [_vm._v("Click on advanced settings or site permission settings")]), _c('li', [_vm._v("Click on popups and redirection")]), _c('li', [_vm._v("And allow for this site")])]), _vm._v(" Or "), _c('div', [_c('ul', [_c('li', [_vm._v(" You can see block icon in your address bar. You can enable from there. ")])])])]), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              _vm.popupDisabledDialog = false;
            }
          }
        }, [_vm._v(" Ok, got it ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.popupDisabledDialog,
      callback: function callback($$v) {
        _vm.popupDisabledDialog = $$v;
      },
      expression: "popupDisabledDialog"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-upgrade-component": "",
            "upgrade-plan-text": _vm.upgradePlanMessage
          },
          on: {
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            },
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.confirmationModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" You are about to start the " + _vm._s(_vm.trialDay) + "-day free trial for the "), _c('div', [_c('b', {
          attrs: {
            "id": "selectedPlanName"
          }
        }, [_vm._v("Starter "), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(Monthly) ")])]), _vm._v("plan ")])]), _c('v-col', {
          staticClass: "text-center pb-0",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "upgradePlanBtn",
            "color": "primary",
            "loading": _vm.busy,
            "depressed": ""
          },
          on: {
            "click": _vm.startFreeTrial
          }
        }, [_vm._v(" Let's do it 💪 ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.confirmationModal,
      callback: function callback($$v) {
        _vm.confirmationModal = $$v;
      },
      expression: "confirmationModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.showUninstallModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$selectedSource2, _vm$selectedIntegrati5, _vm$selectedIntegrati6;
        return [_vm.showUninstallModal ? _c('v-card', [_c('v-card-title', [_vm._v(" Disconnect " + _vm._s((_vm$selectedSource2 = _vm.selectedSource) === null || _vm$selectedSource2 === void 0 ? void 0 : _vm$selectedSource2.name) + " ")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
          staticClass: "font-16"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Heads up! You're about to remove "), _c('b', {
          attrs: {
            "id": "selectedName"
          }
        }, [_vm._v(_vm._s(_vm.selectedSource.name))]), _vm._v(" from "), _c('b', [_vm._v(_vm._s((_vm$selectedIntegrati5 = _vm.selectedIntegration) === null || _vm$selectedIntegrati5 === void 0 ? void 0 : _vm$selectedIntegrati5.name))]), _vm._v(", which will remove all "), _c('b', [_vm._v(_vm._s(_vm.titleSubMap[(_vm$selectedIntegrati6 = _vm.selectedIntegration) === null || _vm$selectedIntegrati6 === void 0 ? void 0 : _vm$selectedIntegrati6.name].toLowerCase()) + "s")]), _vm._v(" and connected apps. ")])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "text": "",
            "disabled": _vm.busy,
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showUninstallModal = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "color": "error",
            "loading": _vm.busy
          },
          on: {
            "click": _vm.confirmUnistall
          }
        }, [_vm._v(" Disconnect ")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showUninstallModal,
      callback: function callback($$v) {
        _vm.showUninstallModal = $$v;
      },
      expression: "showUninstallModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.microsoftTeamModal ? _c('microsoft-teams-form', {
          on: {
            "close": function close($event) {
              _vm.microsoftTeamModal = false;
            },
            "success": _vm.getIntegrationSourceList
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.microsoftTeamModal,
      callback: function callback($$v) {
        _vm.microsoftTeamModal = $$v;
      },
      expression: "microsoftTeamModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.jiraConnectModal ? _c('jira-configure-form', {
          on: {
            "close": function close($event) {
              _vm.jiraConnectModal = false;
            },
            "success": _vm.getIntegrationSourceList
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.jiraConnectModal,
      callback: function callback($$v) {
        _vm.jiraConnectModal = $$v;
      },
      expression: "jiraConnectModal"
    }
  }), _c('Modal', {
    attrs: {
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 1100 : '',
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.openSubscriptionModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('plan-pricing', {
          attrs: {
            "from-modal": "",
            "from-upgrade-component": "",
            "upgrade-plan-text": _vm.upgradePlanMessage
          },
          on: {
            "success": function success($event) {
              _vm.$emit('close'), _vm.openSubscriptionModal = false;
            },
            "close": function close($event) {
              _vm.openSubscriptionModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSubscriptionModal,
      callback: function callback($$v) {
        _vm.openSubscriptionModal = $$v;
      },
      expression: "openSubscriptionModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.confirmationModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "py-4"
        }, [_c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" You are about to start the " + _vm._s(_vm.trialDay) + "-day free trial for the "), _c('div', [_c('b', {
          attrs: {
            "id": "selectedPlanName"
          }
        }, [_vm._v("Starter "), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("(Monthly) ")])]), _vm._v("plan ")])]), _c('v-col', {
          staticClass: "text-center pb-0",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "upgradePlanBtn",
            "color": "primary",
            "loading": _vm.busy,
            "depressed": ""
          },
          on: {
            "click": _vm.startFreeTrial
          }
        }, [_vm._v(" Let's do it 💪 ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.confirmationModal,
      callback: function callback($$v) {
        _vm.confirmationModal = $$v;
      },
      expression: "confirmationModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? '800' : ''
    },
    on: {
      "close": function close($event) {
        _vm.slackInfoPopup = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-img', {
          attrs: {
            "src": "/media/instruction/slack_integration.png"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('v-row', {
          staticClass: "pt-8"
        }, [_c('v-col', {
          staticClass: "font-size-h3 darkGrey--text bold-text",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Enable slack integration ")]), _c('v-col', {
          staticClass: "pt-0 bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', [_vm._v(" By connecting your Slack, your channel will be notified about important events in your teams"), _c('br'), _c('br'), _c('b', [_vm._v("Events:")]), _vm._v(" "), _c('br'), _c('br'), _c('b', [_vm._v("Team")]), _c('br'), _vm._v(" - A member accepts or declines a team invite"), _c('br'), _vm._v(" - A member shares iOS UDID "), _c('br'), _vm._v(" - Removing and leaving the team"), _c('br'), _vm._v(" - Deleting team"), _c('br'), _c('br'), _c('b', [_vm._v("App")]), _c('br'), _vm._v(" - New app"), _c('br'), _vm._v(" - Update app info"), _c('br'), _vm._v(" - Adding a member to the app"), _c('br'), _vm._v(" - Removing a member from the app"), _c('br'), _vm._v(" - Removing and leaving members from the app"), _c('br'), _vm._v(" - Deleting app"), _c('br'), _c('br'), _c('b', [_vm._v("Release")]), _c('br'), _vm._v(" - New release"), _c('br'), _vm._v(" - Updated release notes"), _c('br'), _vm._v(" - Moving releases between apps"), _c('br'), _c('br'), _vm._v("Keep your team updated with Slack integrations! ")])])], 1)], 1), !_vm.canManageTeam ? _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "error--text"
        }, [_vm._v(" You don't have the permission to manage the team's integrations ")])]) : _vm._e(), _vm.canManageTeam ? _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [!_vm.showTrialButton && !_vm.isPaidUser ? _c('v-btn', {
          staticClass: "text-right text-transform-none",
          attrs: {
            "depressed": "",
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }, [_vm._v(" Upgrade now ")]) : _vm._e(), _vm.showTrialButton && !_vm.isPaidUser ? _c('v-btn', {
          staticClass: "text-right text-transform-none",
          attrs: {
            "depressed": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        }, [_vm._v(" Start free trial ")]) : _vm._e()], 1) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.slackInfoPopup,
      callback: function callback($$v) {
        _vm.slackInfoPopup = $$v;
      },
      expression: "slackInfoPopup"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }