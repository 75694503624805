var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" JIRA Integration ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|url',
      expression: "'required|url'"
    }],
    attrs: {
      "filled": ""
    },
    model: {
      value: _vm.formValues.server_url,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "server_url", $$v);
      },
      expression: "formValues.server_url"
    }
  }, 'v-text-field', _vm.veeValidate('Url', 'JIRA server url'), false))], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    on: {
      "click": function click($event) {
        return _vm.initiateJiraFieldInformation('server');
      }
    }
  }, [_vm._v("mdi-help-circle-outline")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "filled": ""
    },
    model: {
      value: _vm.formValues.username,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "username", $$v);
      },
      expression: "formValues.username"
    }
  }, 'v-text-field', _vm.veeValidate('username', 'Username'), false))], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    on: {
      "click": function click($event) {
        return _vm.initiateJiraFieldInformation('username');
      }
    }
  }, [_vm._v("mdi-help-circle-outline")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "filled": ""
    },
    model: {
      value: _vm.formValues.api_token,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "api_token", $$v);
      },
      expression: "formValues.api_token"
    }
  }, 'v-text-field', _vm.veeValidate('api token', 'API Token'), false))], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    on: {
      "click": function click($event) {
        return _vm.initiateJiraFieldInformation('api-token');
      }
    }
  }, [_vm._v("mdi-help-circle-outline")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.confirmConnectJira
    }
  }, [_vm._v(" Connect ")])], 1), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', {
          staticClass: "pa-6"
        }, [_c('v-row', [_vm.infoType === 'server' ? _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "titleFont my-2 semi-bold"
        }, [_vm._v("JIRA Server URL:")]), _c('ul', {
          staticClass: "bodyFont"
        }, [_c('li', [_vm._v(" The JIRA Server URL is the web address you use to access your JIRA instance. ")]), _c('li', [_vm._v(" How to Find: "), _c('ul', [_c('li', [_vm._v("Log into your JIRA account.")]), _c('li', [_vm._v(" The URL in your browser's address bar when you are on the dashboard is your JIRA Server URL. ")]), _c('li', [_vm._v(" It typically looks like "), _c('code', [_vm._v(" https://yourcompany.atlassian.net.")])])])])])]) : _vm._e(), _vm.infoType === 'username' ? _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "titleFont my-2 semi-bold"
        }, [_vm._v("JIRA Username:")]), _c('ul', {
          staticClass: "bodyFont"
        }, [_c('li', [_vm._v(" This is the username you use to log into your JIRA account. ")]), _c('li', [_vm._v(" How to Find: "), _c('ul', [_c('li', [_vm._v(" If you are unsure of your username, you can find it in the account settings of your JIRA profile. ")]), _c('li', [_vm._v(" Go to the top-right corner of the JIRA dashboard, click on your profile icon, and select 'Account settings' or 'Profile'. ")]), _c('li', [_vm._v("Your username is usually displayed on this page.")])])])])]) : _vm._e(), _vm.infoType === 'api-token' ? _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "titleFont my-2 semi-bold"
        }, [_vm._v("JIRA API Token:")]), _c('ul', {
          staticClass: "bodyFont"
        }, [_c('li', [_vm._v(" An API token is used for secure authentication when integrating with JIRA APIs. ")]), _c('li', [_vm._v(" How to create: "), _c('ul', [_c('li', [_vm._v(" Visit the Atlassian Account's API Token page: "), _c('a', {
          attrs: {
            "href": " https://id.atlassian.com/manage-profile/security/api-tokens",
            "target": "_blank"
          }
        }, [_vm._v(" https://id.atlassian.com/manage-profile/security/api-tokens")]), _vm._v(". ")]), _c('li', [_vm._v("Log in with your JIRA account credentials.")]), _c('li', [_vm._v("Click on ‘Create API token’.")]), _c('li', [_vm._v(" In the dialog that appears, provide a label for your token (e.g., 'Integration with XYZ'). ")]), _c('li', [_vm._v("Click ‘Create’ to generate the token.")]), _c('li', [_vm._v(" Copy the token to a secure place. It won’t be shown again. ")])])])])]) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showWebhookInfoModal,
      callback: function callback($$v) {
        _vm.showWebhookInfoModal = $$v;
      },
      expression: "showWebhookInfoModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }