<template>
  <v-card>
    <v-card-title>
      {{ selectedChannel ? "Update" : "Add" }}
      {{ titleMap[selectedIntegration?.name] }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="bodyFont semi-bold" cols="12">
          <div>
            <div>
              {{ selectedSource?.name }}
            </div>
          </div>
        </v-col>

        <v-col cols="12">
          <v-select
            filled
            v-model="formValues.project"
            :items="projectList"
            item-text="name"
            return-object
            v-validate="'required'"
            v-bind="veeValidate('Project', `Project`)"
          >
            <!-- <template #v-slot:selected="data"></template> -->
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-select
            filled
            :disabled="!formValues.project"
            v-model="formValues.info.issue"
            :items="formValues?.project?.issueTypes"
            item-value="id"
            item-text="name"
            return-object
            v-validate="'required'"
            v-bind="veeValidate('issue', 'Issue type')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-row align="center">
            <v-col cols="9">
              <v-autocomplete
                v-model="formValues.info.assignee"
                :items="assigneList"
                :loading="assigneeLoading"
                :search-input.sync="assigneeSearch"
                label="Select assignee"
                dense
                filled
                v-validate="'required'"
                v-bind="veeValidate('assignee', 'Assignee')"
                item-text="emailAddress"
                item-value="id"
                return-object
              >
                <template v-slot:selection="{ item }">
                  <div class="smallFont">
                    <div>
                      {{ item.emailAddress || item.displayName }}
                    </div>
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item @click="formValues.info.assignee = item">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.displayName
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.emailAddress
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:no-data>
                  <v-list-item v-if="!assigneeSearch">
                    Type user email
                  </v-list-item>
                  <v-list-item v-else-if="!assigneList">
                    Hit search</v-list-item
                  >
                  <v-list-item v-else-if="assigneList">
                    No email found</v-list-item
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="text-right" cols="3">
              <v-btn
                :loading="assigneeLoading"
                color="primary"
                outlined
                :disabled="!assigneeSearch"
                class="text-transform-none"
                @click="getUserList('assignee')"
              >
                <v-icon left>mdi-magnify</v-icon>
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center">
            <v-col cols="9">
              <v-autocomplete
                v-model="formValues.info.reporter"
                :items="reporterList"
                :loading="reporterLoading"
                :search-input.sync="reporterSearch"
                dense
                label="Select reporter"
                v-validate="'required'"
                v-bind="veeValidate('reporter', 'Reporter')"
                filled
                item-text="emailAddress"
                item-value="id"
                return-object
              >
                <template v-slot:selection="{ item }">
                  <div>
                    <div>
                      {{ item.emailAddress }}
                    </div>
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item @click="formValues.info.reporter = item">
                    {{ item.emailAddress || item.displayName }}
                  </v-list-item>
                </template>
                <template v-slot:no-data>
                  <v-list-item v-if="!reporterSearch">
                    Type user email
                  </v-list-item>
                  <v-list-item v-else-if="!reporterList">
                    Hit search</v-list-item
                  >
                  <v-list-item v-else-if="reporterList">
                    No email found</v-list-item
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-btn
                :loading="reporterLoading"
                color="primary"
                :disabled="!reporterSearch"
                outlined
                class="text-transform-none"
                @click="getUserList('reporter')"
              >
                <v-icon left>mdi-magnify</v-icon>
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="text-transform-none"
        text
        :disabled="busy"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-transform-none"
        :outlined="$vuetify.theme.dark"
        :loading="busy"
        @click="confirmSubmit"
      >
        <span v-if="fromWorkspace">Add</span>
        <span v-else>
          {{ selectedChannel ? "Update" : "Add" }}
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import veeValidate from "@/mixins/veeValidate";
import { mapGetters } from "vuex";
export default {
  name: "SlackAddChannelForm",
  mixins: [veeValidate],
  props: {
    selectedChannel: {
      type: Object,
      default() {
        return null;
      },
    },
    selectedSource: {
      type: Object,
      required: true,
    },
    fromWorkspace: {
      type: Boolean,
      default: false,
    },

    workspaceDetail: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      showWebhookInfoModal: false,
      formValues: {
        project: null,
        uid: "",
        name: "",
        info: {
          assignee: "",
          reporter: "",
          issue: "",
        },
      },
      loading: false,
      projectList: [],
      reporterList: null,
      assigneList: null,
      reporterSearch: "",
      assigneeSearch: "",
      selectedProject: {},
      assigneeLoading: false,
      reporterLoading: false,
      microsoftTeamWebhook: "",
      regexValidEmail: new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
      busy: false,
      titleMap: {
        Slack: "Channel",
        "Microsoft Teams": "Channel",
        JIRA: "Project",
      },
    };
  },

  // computed: {
  //   ...map,
  // },
  mounted() {},
  computed: {
    ...mapGetters({
      selectedIntegration: "getSelectedIntegration",
    }),
  },
  watch: {},
  created() {
    this.getOptionsListForSelect("projects");
  },
  methods: {
    getOptionsListForSelect(type) {
      if (this.loading) return;
      this.loading = true;
      this.$portalApi
        .post(
          this.apiRoutes.portal.integrations.jira.customSearch.projects(
            this.$route.params.integration_id,
            this.selectedSource.id,
            type
          )
        )
        .then(({ data }) => {
          this.loading = false;
          this.projectList = data;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    getUserList(type) {
      const searchEmail =
        type === "assignee" ? "assigneeSearch" : "reporterSearch";
      if (!this[searchEmail] || !this.regexValidEmail.test(this[searchEmail])) {
        this.notifyErrorMessage({
          message: "Please enter a valid email address to search",
        });
        return;
      }
      const loading =
        type === "assignee" ? "assigneeLoading" : "reporterLoading";
      if (this[loading]) return;
      this[loading] = true;
      this.$portalApi
        .post(
          this.apiRoutes.portal.integrations.jira.customSearch.projects(
            this.$route.params.integration_id,
            this.selectedSource.id,
            "users"
          ),
          {
            email:
              type === "assignee" ? this.assigneeSearch : this.reporterSearch,
          }
        )
        .then(({ data }) => {
          this[loading] = false;
          if (type === "assignee") {
            this.assigneList = data;
            if (data?.length) {
              this.formValues.info.assignee = data[0];
            }
          } else {
            this.reporterList = data;
            if (data?.length) {
              this.formValues.info.reporter = data[0];
            }
          }
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this[loading] = false;
        });
    },
    confirmSubmit() {
      this.addNewChannel();
    },
    async addNewChannel() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        let dataToPost = {};

        dataToPost = {
          uid: this.formValues?.project?.key,
          name: this.formValues?.project?.name,
          info: {
            assignee: {
              id: this.formValues?.info?.assignee?.accountId,
              name: this.formValues?.info?.assignee?.displayName,
              email: this.formValues?.info?.assignee?.emailAddress,
            },
            reporter: {
              id: this.formValues?.info?.reporter?.accountId,
              name: this.formValues?.info?.reporter?.displayName,
              email: this.formValues?.info?.reporter?.emailAddress,
            },
            issue: {
              id: this.formValues?.info?.issue?.id,
              name: this.formValues?.info?.issue?.name,
            },
          },
        };
        this.$portalApi
          .post(
            this.apiRoutes.portal.integrations.sourceSub.create.slack(
              this.$route.params.integration_id,
              this.selectedSource.id
            ),
            dataToPost
          )
          .then(({ data }) => {
            this.busy = false;
            this.notifyUserMessage({ message: data.message });
            this.$emit("sucess");
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
            this.busy = false;
          });
      }
    },
    updateChannel() {
      if (this.busy) return;
      this.busy = true;
      let dataToPost = {};
      if (this.selectedIntegration?.name === "Slack") {
        dataToPost = { ...this.formValues };
      }
      if (this.selectedIntegration?.name === "JIRA") {
        dataToPost = {
          ...this.formValues,
          info: { ...this.selectedSource?.info },
        };
      }
      this.$portalApi
        .put(
          this.apiRoutes.portal.integrations.sourceSub.update(
            this.$route.params.integration_id,
            this.selectedSource.id,
            this.selectedChannel.id
          ),
          dataToPost
        )
        .then(({ data }) => {
          this.busy = false;
          this.notifyUserMessage({ message: data.message });
          this.$emit("sucess");
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
  },
};
</script>
