var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-3"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.selectedSource ? "Update" : "Connect with") + " Microsoft Teams ")]), _c('v-card-text', [_c('v-row', {
    staticClass: "font-16"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    },
    on: {
      "click": function click($event) {
        _vm.enlargeImage = true;
      }
    }
  }, [_c('v-img', {
    attrs: {
      "height": "300",
      "src": "/media/integrations/microsoft_teams/1.png",
      "contain": ""
    }
  })], 1), !_vm.selectedSource ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "filled": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }, 'v-text-field', _vm.veeValidate('name', 'Team name'), false))], 1) : _vm._e()], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "disabled": _vm.loading,
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.loading,
      "color": "primary",
      "depressed": "",
      "disabled": _vm.errors.any(),
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.confirmRequest
    }
  }, [_vm._v(" Connect ")])], 1), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.enlargeImage ? _c('v-card', {
          staticClass: "pa-3"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-img', {
          attrs: {
            "src": "/media/integrations/microsoft_teams/1.png"
          }
        })], 1)], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.enlargeImage,
      callback: function callback($$v) {
        _vm.enlargeImage = $$v;
      },
      expression: "enlargeImage"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }