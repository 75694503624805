var render = function render(){
  var _vm$selectedIntegrati, _vm$selectedIntegrati2, _vm$selectedIntegrati3, _vm$selectedIntegrati4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "darkGrey--text"
  }, [_c('div', [_c('div', {
    staticClass: "semi-bold bodyFont"
  }, [_vm._v(" " + _vm._s(_vm.titleMap[(_vm$selectedIntegrati = _vm.selectedIntegration) === null || _vm$selectedIntegrati === void 0 ? void 0 : _vm$selectedIntegrati.name]) + "s ")]), _c('div', {
    staticClass: "smallFont"
  }, [_vm._v(" Each app can be connected to one " + _vm._s(_vm.titleMap[(_vm$selectedIntegrati2 = _vm.selectedIntegration) === null || _vm$selectedIntegrati2 === void 0 ? void 0 : _vm$selectedIntegrati2.name].toLowerCase()) + ". ")])])]), _vm.canManageTeam ? _c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "depressed": "",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.openAddChannelForm
    }
  }, [_vm._v(" Add " + _vm._s(_vm.titleMap[(_vm$selectedIntegrati3 = _vm.selectedIntegration) === null || _vm$selectedIntegrati3 === void 0 ? void 0 : _vm$selectedIntegrati3.name]))])], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', _vm._l(5, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "card, list-item"
      }
    })], 1);
  }), 1)], 1) : _vm._e(), !_vm.loading && !_vm.subSourceList.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "No ".concat(_vm.titleMap[(_vm$selectedIntegrati4 = _vm.selectedIntegration) === null || _vm$selectedIntegrati4 === void 0 ? void 0 : _vm$selectedIntegrati4.name], "s  found")
    }
  })], 1) : _vm._e(), _vm.subSourceList.length && !_vm.loading ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('DatatableWrapper', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.subSourceList,
      "hide-default-header": "",
      "hide-default-footer": "",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "py-4"
        }, [_c('div', {
          staticClass: "bodyFont semi-bold"
        }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
          staticClass: "smallFont"
        }, [_vm._v(_vm._s(item.uid))])])];
      }
    }, {
      key: "item.app",
      fn: function fn(_ref2) {
        var _item$stats, _item$stats2;
        var item = _ref2.item;
        return [_c('div', [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.openAppDrawer(item);
            }
          }
        }, [_c('span', {
          staticClass: "bodyFont primary--text semi-bold"
        }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : _item$stats.apps) || "0") + " ")])]), _c('div', {
          staticClass: "smallFont cursor-pointer",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.openAppDrawer(item);
            }
          }
        }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : _item$stats2.apps) == 1 ? "App" : "Apps") + " ")])], 1)];
      }
    }, {
      key: "item.user",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('user-avatar', {
          attrs: {
            "user-detail": item.user
          }
        })];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.is_default ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on;
              return [_c('v-chip', _vm._g({
                attrs: {
                  "small": "",
                  "outlined": "",
                  "color": "primary"
                },
                domProps: {
                  "textContent": _vm._s('Team')
                }
              }, on))];
            }
          }], null, true)
        }, [_c('div', [_vm._v("Alerts related to team")])]) : _vm._e()];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm.canManageTeam ? _c('div', [_c('action-dropdown', {
          attrs: {
            "id": "membersActionDropdown"
          },
          scopedSlots: _vm._u([{
            key: "list",
            fn: function fn() {
              return [item.type === 'alert' && !item.is_default && _vm.canManageTeam ? _c('v-list-item', {
                attrs: {
                  "disabled": _vm.busy
                },
                on: {
                  "click": function click($event) {
                    return _vm.makeTeamDefault(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-application")]), _vm._v(" Make Team Default ")], 1) : _vm._e(), _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.openAppDrawer(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "left": "",
                  "small": ""
                }
              }, [_vm._v("apps")]), _vm._v(" Manage Apps ")], 1), _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.deleteChannel(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("cancel")]), _vm._v(" Remove ")], 1)];
            },
            proxy: true
          }], null, true)
        })], 1) : _vm._e()];
      }
    }], null, false, 784513909)
  })], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1)], 1), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showCreateChannelForm ? _c('add-slack-channel-form', {
          attrs: {
            "selected-source": _vm.selectedSource
          },
          on: {
            "close": function close($event) {
              _vm.showCreateChannelForm = false;
            },
            "sucess": _vm.afterChannelSuccess
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showCreateChannelForm,
      callback: function callback($$v) {
        _vm.showCreateChannelForm = $$v;
      },
      expression: "showCreateChannelForm"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showCreateJiraProjectForm ? _c('jira-create-project-form', {
          attrs: {
            "selected-source": _vm.selectedSource
          },
          on: {
            "close": function close($event) {
              _vm.showCreateJiraProjectForm = false;
            },
            "sucess": _vm.afterChannelSuccess
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showCreateJiraProjectForm,
      callback: function callback($$v) {
        _vm.showCreateJiraProjectForm = $$v;
      },
      expression: "showCreateJiraProjectForm"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showSubSourceDeleteConfirm ? _c('v-card', [_c('v-card-title', [_vm._v(" Remove " + _vm._s(_vm.selectedSubSource.name) + " ")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
          staticClass: "font-16"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Heads up! You're about to remove "), _c('b', {
          attrs: {
            "id": "selectedName"
          }
        }, [_vm._v(_vm._s(_vm.selectedSubSource.name))]), _vm._v(" from "), _c('b', [_vm._v(_vm._s(_vm.selectedSource.name))]), _vm._v(", which will also remove it from for all connected apps. ")])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showSubSourceDeleteConfirm = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "color": "error"
          },
          on: {
            "click": _vm.deleteChannelConfirm
          }
        }, [_vm._v(" Remove ")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showSubSourceDeleteConfirm,
      callback: function callback($$v) {
        _vm.showSubSourceDeleteConfirm = $$v;
      },
      expression: "showSubSourceDeleteConfirm"
    }
  }), _c('SidePanel', {
    model: {
      value: _vm.showAppInfo,
      callback: function callback($$v) {
        _vm.showAppInfo = $$v;
      },
      expression: "showAppInfo"
    }
  }, [_vm.showAppInfo ? _c('AppsInfo', {
    attrs: {
      "from-integration": "",
      "selected-sub-source": _vm.selectedSubSource,
      "integration-sub-id": _vm.selectedSubSource.id,
      "integrations-endpoint": _vm.integrationEndpoint
    },
    on: {
      "close": function close($event) {
        _vm.showAppInfo = false;
      },
      "success": _vm.addAppCountToSubSource,
      "delete": _vm.removeAppCountToSubSource
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }