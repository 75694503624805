<template>
  <div>
    <v-row v-if="integrationDetailErrorMessage">
      <v-col cols="12">
        <no-data :first-text="integrationDetailErrorMessage"></no-data>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="smallFont darkGrey--text" cols="12">
        <span>Team </span>/
        <span
          @click="
            $router.push({
              name: 'team-integrations',
            })
          "
          class="cursor-pointer info--text"
          >Integrations</span
        >
        /
        <span class="info--text"> {{ integrationDetail?.name }}</span>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          outlined
          class="text-transform-none"
          @click="gotoLastPage"
        >
          <v-icon left v-text="'mdi-chevron-left'"></v-icon>
          Go Back
        </v-btn>
      </v-col>
      <v-col v-if="loading" cols="12">
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader type="card,list"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else-if="!loading && !isEmpty(integrationDetail)" cols="12">
        <v-card v-if="integrationDetail" outlined class="pa-3">
          <v-row align="center">
            <v-col class="">
              <v-row align="center">
                <v-col cols="auto">
                  <v-img
                    class="mb-2"
                    height="40"
                    contain
                    width="40"
                    v-if="integrationDetail.image"
                    :src="`/media/integrations/${integrationDetail.image}`"
                  ></v-img>
                </v-col>
                <v-col>
                  <div>
                    <span class="semi-bold title">
                      {{ integrationDetail?.name }}
                    </span>
                    <v-btn
                      @click="showIntegrationHelp(integrationDetail)"
                      icon
                      small
                    >
                      <v-icon small v-text="'mdi-help-circle-outline'"></v-icon>
                    </v-btn>
                  </div>
                  <div class="smallFont">
                    {{
                      integrationDetail?.summary || "Connect this integrations"
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <template v-if="integrationDetail?.meta?.chips?.length">
                <v-chip
                  v-for="(chip, cindex) in integrationDetail?.meta?.chips"
                  :key="cindex"
                  class="mr-1"
                >
                  {{ chip }}
                </v-chip>
              </template>
              <template v-if="integrationDetail?.meta?.labels?.length">
                <v-chip
                  v-for="(label, lindex) in integrationDetail?.meta?.labels"
                  :key="lindex"
                  class="mr-1"
                  :color="label.type"
                >
                  {{ label.text }}
                </v-chip>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        v-if="
          !integrationDetailErrorMessage &&
          !isEmpty(integrationDetail) &&
          !loading
        "
        cols="12"
      >
        <v-row>
          <v-col cols="12">
            <integration-source
              :selected-integration="integrationDetail"
            ></integration-source>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <Modal v-model="confirmationPopup">
      <template #card>
        <v-card>
          <v-card-title> Congratulations 🎉 </v-card-title>
          <v-card-text>
            <v-col class="text-center" cols="12">
              <v-progress-circular
                indeterminate
                color="primary"
                size="24"
              ></v-progress-circular>
            </v-col>
            <v-col class="text-center text-h6" cols="12">
              We are processing the integration <br />
              and connecting with your slack account.
            </v-col>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
    <Modal
      closeable
      v-model="integrationHelpModal"
      @close="integrationHelpModal = false"
    >
      <template #card>
        <v-card class="pa-3">
          <v-card-text>
            <v-row>
              <v-col
                class="bodyFont"
                v-html="integrationHelpDescription"
                cols="12"
              >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
  </div>
</template>

<script>
import { isEmpty } from "@/core/services/helper.service";
import { mapGetters, mapMutations } from "vuex";
import IntegrationSource from "@/view/components/Team/Integrations/IntegrationSource.vue";

export default {
  components: {
    IntegrationSource,
  },
  data() {
    return {
      isEmpty,
      selectedTab: 0,
      tab: 0,
      confirmationModal: false,
      busy: false,
      showUninstallModal: false,
      openSubscriptionModal: false,
      integrationDetail: {},
      integrationHelpDescription: "",
      integrationHelpModal: false,
      authDetails: {},
      extractLoading: false,
      confirmationPopup: false,
      loading: false,
      integrationDetailErrorMessage: null,
      slackEnabled: false,
    };
  },
  computed: {
    ...mapGetters({
      getIntegrationList: "getIntegrationList",
      getTeamUsageInformation: "getTeamUsageInformation",
      getTeamTrial: "getTeamTrial",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
  },
  created() {
    if (this.$route.params.integration_id) {
      this.getIntegrationDetail();
    }

    if (
      this.$route.name === "integration-source-auth" &&
      !isEmpty(this.$route.query)
    ) {
      this.extractDetail();
      this.confirmationPopup = true;
    }
  },
  methods: {
    ...mapMutations({
      setSelectedIntegration: "setSelectedIntegration",
    }),
    getIntegrationDetail() {
      this.integrationDetailErrorMessage = null;
      if (this.loading) return;
      this.loading = true;
      this.$portalApi
        .get(
          `${this.apiRoutes?.portal?.integrations?.detail(
            this.$route.params.integration_id
          )}`
        )
        .then((data) => {
          this.integrationDetail = data?.data || {};
          this.setSelectedIntegration(data?.data);
          this.loading = false;
        })
        .catch((err) => {
          this.integrationDetailErrorMessage = err.message;
          this.loading = false;
        });
    },

    gotoLastPage() {
      if (this.$route.name === "integration-source") {
        this.$router.push({
          name: "team-integrations",
        });
      } else {
        this.$router.push({
          name: "integration-source",
        });
      }
    },
    extractDetail() {
      const details = { ...this.$route.query };
      const hash = this.$route.hash;
      if (hash && hash?.startsWith("#") && !details.channel) {
        let data = hash.split("&");
        details.channel = data[0];
        details.url = data[3] ? data[3].split("=")[1] : "";
        details.channel_id = data[1] ? data[1].split("=")[1] : "";
        details.configuration_url = data[2] ? data[2].split("=")[1] : "";
      }
      const dataToPost = {
        token: details.token,
        incoming: {
          url: details.url || "",
          channel: details.channel || "",
          channel_id: details.channel_id || "",
          configuration_url: details.configuration_url || "",
        },
        team_id: details.team_id,
        team_name: details.team_name,
      };
      if (this.extractLoading) return;
      this.extractLoading = true;
      this.$portalApi
        .post(
          this.apiRoutes.portal.integrations.source.connect.slack("NmeYG02DJ8"),
          dataToPost
        )
        .then((response) => {
          this.confirmationPopup = false;
          this.extractLoading = false;
          this.getIntegrationDetail();
          this.$router.push({
            name: "integration-source",
            params: { integration_id: "NmeYG02DJ8" },
          });
          this.notifyUserMessage({ message: response.data.message });
        })
        .catch((err) => {
          this.confirmationPopup = false;
          this.extractLoading = false;
          this.confirmationPopup = false;
          this.notifyErrorMessage(err.message);
          this.$router.push({
            name: "integration-source",
            params: { integration_id: "NmeYG02DJ8" },
          });
        });
    },
    showIntegrationHelp(detail) {
      this.integrationHelpDescription = detail.description;
      this.integrationHelpModal = true;
    },
  },
};
</script>
