<template>
  <div>
    <v-row>
      <v-col class="text-right" cols="12">
        <v-btn
          v-if="!sourceList.length"
          class="text-transform-none"
          depressed
          color="primary"
          :loading="connectSourceLoading"
          :outlined="$vuetify.theme.dark"
          @click="confirmConnectSource"
        >
          Connect {{ titleMap[selectedIntegration?.name] }}</v-btn
        >
      </v-col>
      <v-col v-if="loading" cols="12">
        <v-row>
          <v-col v-for="i in 3" :key="i">
            <v-skeleton-loader type="card, list"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else-if="!sourceList.length" cols="12">
        <no-data
          :first-text="`Connect your first  ${
            titleMap[selectedIntegration?.name]
          }`"
        ></no-data>
      </v-col>
      <v-col v-else cols="12">
        <v-row>
          <v-col cols="3">
            <v-row>
              <v-col class="darkGrey--text" cols="12">
                <v-row align="center">
                  <v-col class="semi-bold bodyFont">
                    {{ titleMap[selectedIntegration?.name] }}s
                  </v-col>
                  <v-col class="text-right">
                    <v-btn
                      v-if="canManageTeam"
                      class="text-transform-none"
                      depressed
                      color="primary"
                      :loading="connectSourceLoading || slackLoading"
                      :outlined="$vuetify.theme.dark"
                      @click="confirmConnectSource"
                    >
                      Connect {{ titleMap[selectedIntegration?.name] }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-for="(item, index) in sourceList" :key="index" cols="12">
                <v-card
                  @click="selectedSource = item"
                  :class="item.id === selectedSource?.id ? 'activeCard' : ''"
                  outlined
                  flat
                >
                  <v-card-title>
                    <div>
                      <div class="semi-bold">
                        {{ item.name }}
                      </div>
                      <div class="smallFont">{{ item.uid }}</div>
                    </div>
                    <v-spacer></v-spacer>
                    <action-dropdown
                      v-if="canManageTeam"
                      id="membersActionDropdown"
                    >
                      <template #list>
                        <v-list-item @click="addChannelInfo(item)">
                          <v-icon small>mdi-plus</v-icon>
                          Add Channel
                        </v-list-item>

                        <v-list-item @click="removeIntegraion(item)">
                          <v-icon small>cancel</v-icon>
                          Disconnect
                        </v-list-item>
                      </template>
                    </action-dropdown>
                  </v-card-title>
                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="auto">
                        <span>
                          <user-avatar :user-detail="item.user"></user-avatar>
                        </span>
                      </v-col>
                      <v-col cols="auto">
                        <span>
                          <vue-hoverable-date
                            :date="item.created_at"
                          ></vue-hoverable-date>
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pt-15" cols="9">
            <integration-sub-source
              v-if="selectedSource"
              :selected-integration="selectedIntegration"
              :key="selectedSource.id"
              :selected-source="selectedSource"
            ></integration-sub-source>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Modal v-model="showCreateChannelForm">
      <template #card>
        <add-slack-channel-form
          v-if="showCreateChannelForm"
          :selected-source="selectedSource"
          @close="showCreateChannelForm = false"
          @sucess="afterChannelSuccess"
        ></add-slack-channel-form>
      </template>
    </Modal>
    <Modal v-model="popupDisabledDialog" @close="popupDisabledDialog = false">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-left text-h5 mt-4" cols="12">
            Oops! Seems like you have disabled popups for this site.
          </v-col>
          <v-col class="text-left text-h6" cols="12">
            Follow below steps to enable popups for this site.
            <ul>
              <li>Go to your browser settings</li>
              <li>Click on advanced settings or site permission settings</li>
              <li>Click on popups and redirection</li>
              <li>And allow for this site</li>
            </ul>
            Or
            <div>
              <ul>
                <li>
                  You can see block icon in your address bar. You can enable
                  from there.
                </li>
              </ul>
            </div>
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              class="text-transform-none"
              color="primary"
              depressed
              @click="popupDisabledDialog = false"
            >
              Ok, got it
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="openSubscriptionModal = false"
      v-model="openSubscriptionModal"
    >
      <template #message>
        <plan-pricing
          from-modal
          from-upgrade-component
          :upgrade-plan-text="upgradePlanMessage"
          @success="$emit('close'), (openSubscriptionModal = false)"
          @close="openSubscriptionModal = false"
        ></plan-pricing>
      </template>
    </Modal>
    <Modal v-model="confirmationModal" @close="confirmationModal = false">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-center text-h6" cols="12">
            You are about to start the {{ trialDay }}-day free trial for the
            <div>
              <b id="selectedPlanName"
                >Starter <span class="text-capitalize">(Monthly) </span></b
              >plan
            </div>
          </v-col>
          <v-col class="text-center pb-0" cols="12">
            <v-btn
              id="upgradePlanBtn"
              @click="startFreeTrial"
              class="text-transform-none"
              color="primary"
              :loading="busy"
              depressed
            >
              Let's do it 💪
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal v-model="showUninstallModal" @close="showUninstallModal = false">
      <template #card>
        <v-card v-if="showUninstallModal">
          <v-card-title> Disconnect {{ selectedSource?.name }} </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="font-16">
              <v-col cols="12">
                Heads up! You're about to remove
                <b id="selectedName">{{ selectedSource.name }}</b>
                from <b>{{ selectedIntegration?.name }}</b
                >, which will remove all
                <b
                  >{{
                    titleSubMap[selectedIntegration?.name].toLowerCase()
                  }}s</b
                >
                and connected apps.
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="showUninstallModal = false"
              text
              class="text-transform-none"
              :disabled="busy"
              color="primary"
            >
              Cancel
            </v-btn>
            <v-btn
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="error"
              :loading="busy"
              @click="confirmUnistall"
            >
              Disconnect
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="microsoftTeamModal">
      <template #card>
        <microsoft-teams-form
          v-if="microsoftTeamModal"
          @close="microsoftTeamModal = false"
          @success="getIntegrationSourceList"
        ></microsoft-teams-form>
      </template>
    </Modal>
    <Modal v-model="jiraConnectModal">
      <template #card>
        <jira-configure-form
          v-if="jiraConnectModal"
          @close="jiraConnectModal = false"
          @success="getIntegrationSourceList"
        ></jira-configure-form>
      </template>
    </Modal>
    <Modal
      :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @close="openSubscriptionModal = false"
      v-model="openSubscriptionModal"
    >
      <template #message>
        <plan-pricing
          from-modal
          from-upgrade-component
          :upgrade-plan-text="upgradePlanMessage"
          @success="$emit('close'), (openSubscriptionModal = false)"
          @close="openSubscriptionModal = false"
        ></plan-pricing>
      </template>
    </Modal>
    <Modal v-model="confirmationModal" @close="confirmationModal = false">
      <template #message>
        <v-row class="py-4">
          <v-col class="text-center text-h6" cols="12">
            You are about to start the {{ trialDay }}-day free trial for the
            <div>
              <b id="selectedPlanName"
                >Starter <span class="text-capitalize">(Monthly) </span></b
              >plan
            </div>
          </v-col>
          <v-col class="text-center pb-0" cols="12">
            <v-btn
              id="upgradePlanBtn"
              @click="startFreeTrial"
              class="text-transform-none"
              color="primary"
              :loading="busy"
              depressed
            >
              Let's do it 💪
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? '800' : ''"
      v-model="slackInfoPopup"
      @close="slackInfoPopup = false"
    >
      <template #message>
        <v-row align="center">
          <v-col cols="12" md="6">
            <v-img src="/media/instruction/slack_integration.png"></v-img>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="pt-8">
              <v-col class="font-size-h3 darkGrey--text bold-text" cols="12">
                Enable slack integration
              </v-col>
              <v-col class="pt-0 bodyFont" cols="12">
                <div>
                  By connecting your Slack, your channel will be notified about
                  important events in your teams<br /><br />

                  <b>Events:</b> <br /><br />

                  <b>Team</b><br />
                  - A member accepts or declines a team invite<br />
                  - A member shares iOS UDID <br />
                  - Removing and leaving the team<br />
                  - Deleting team<br /><br />

                  <b>App</b><br />
                  - New app<br />
                  - Update app info<br />
                  - Adding a member to the app<br />
                  - Removing a member from the app<br />
                  - Removing and leaving members from the app<br />
                  - Deleting app<br /><br />

                  <b>Release</b><br />
                  - New release<br />
                  - Updated release notes<br />
                  - Moving releases between apps<br />

                  <br />Keep your team updated with Slack integrations!
                </div>
              </v-col>
              <!-- <v-col cols="12">
                    <v-checkbox
                      v-if="!selectedIntegration.status"
                      v-model="enableSlackForAllApps"
                      label="Enable slack for all apps in this team."
                    ></v-checkbox>
                  </v-col> -->
            </v-row>
          </v-col>
          <v-col v-if="!canManageTeam" class="text-right" cols="12">
            <div class="error--text">
              You don't have the permission to manage the team's integrations
            </div>
          </v-col>
          <v-col v-if="canManageTeam" class="text-right" cols="12">
            <v-btn
              v-if="!showTrialButton && !isPaidUser"
              depressed
              :outlined="$vuetify.theme.dark"
              class="text-right text-transform-none"
              @click="openSubscriptionModal = true"
              color="primary"
            >
              Upgrade now
            </v-btn>
            <v-btn
              v-if="showTrialButton && !isPaidUser"
              depressed
              class="text-right text-transform-none"
              @click="openSubscriptionModal = true"
              color="primary"
            >
              Start free trial
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
  </div>
</template>
<script>
import AddSlackChannelForm from "@/view/components/Team/Integrations/SlackAddChannelForm.vue";
import MicrosoftTeamsForm from "@/view/components/Team/Integrations/MicrosoftTeamsForm.vue";
import JiraConfigureForm from "@/view/components/Team/Integrations/JiraConfigureForm.vue";
import UserAvatar from "@/view/components/Common/UserAvatar";
import IntegrationSubSource from "@/view/components/Team/Integrations/IntegrationSubSource.vue";
import { mapGetters } from "vuex";
import { START_FREE_TRIAL } from "@/store/team/team.module";
import { GET_USER_ROLES } from "@/store/users/auth.module";
import PlanPricing from "@/view/components/Team/PlanPricing";

export default {
  props: {
    selectedIntegration: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddSlackChannelForm,
    IntegrationSubSource,
    MicrosoftTeamsForm,
    UserAvatar,
    PlanPricing,
    JiraConfigureForm,
  },
  data() {
    return {
      confirmationModal: false,
      showUninstallModal: false,
      openSubscriptionModal: false,
      showCreateChannelForm: false,
      loading: false,
      upgradePlanMessage: "",
      slackInfoPopup: false,
      titleMap: {
        Slack: "Workspace",
        "Microsoft Teams": "Team",
        JIRA: "JIRA server",
      },
      titleSubMap: {
        Slack: "Channel",
        "Microsoft Teams": "Channel",
        JIRA: "Project",
      },
      showSubSourceData: false,
      popupDisabledDialog: false,
      selectedSource: null,
      showUpgradeInfo: false,
      busy: false,
      microsoftTeamModal: false,
      jiraConnectModal: false,
      headers: [
        {
          text: "Name/ID",
          value: "name",
        },
        {
          text: "Channels",
          value: "channel",
        },
        {
          text: "Apps",
          value: "app",
        },
        {
          text: "User",
          value: "user",
        },
        // {
        //   text: "Organization",
        //   value: "organization",
        // },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Action",
          align: "right",
          value: "action",
        },
      ],
      sourceList: [
        {
          name: "Car wash",
          id: 2,
          stats: {
            channel: 5,
            apps: 4,
          },
        },
        {
          name: "Delivary",
          id: 44545,
          stats: {
            channel: 5,
            apps: 4,
          },
        },
      ],
      connectSourceLoading: false,
      slackLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getTeamUsageInformation: "getTeamUsageInformation",
      getTeamTrial: "getTeamTrial",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    showTrialButton() {
      return !!(this.getTeamTrial && !this.getTeamTrial.status);
    },

    isPaidUser: {
      get: function () {
        return (
          this.getTeamUsageInformation &&
          this.getTeamUsageInformation.integrations
        );
      },
      set: function () {},
    },
  },
  created() {
    this.getIntegrationSourceList();
  },
  methods: {
    confirmConnectSource() {
      if (this.connectSourceLoading) return;
      this.connectSourceLoading = true;
      this.$portalApi
        .post(this.apiRoutes.portal.integrations.check, {
          id: this.$route.params.integration_id,
        })
        .then(() => {
          if (this.selectedIntegration.name === "Slack") {
            this.connectSourceLoading = false;
            this.generateSlackAuthLink();
          } else if (this.selectedIntegration.name === "Microsoft Teams") {
            this.connectSourceLoading = false;
            this.microsoftTeamModal = true;
          } else if (this.selectedIntegration.id === "Vg7oW5YxKv") {
            this.connectSourceLoading = false;
            this.jiraConnectModal = true;
          }
        })
        .catch((err) => {
          this.connectSourceLoading = false;
          if (err && err.status === 402) {
            this.upgradePlanMessage = err.message;
            this.openSubscriptionModal = true;
          } else {
            this.notifyErrorMessage(err.message);
          }
        });
    },
    openSubSourceList(detail) {
      this.selectedSource = detail;
    },
    removeIntegraion(detail) {
      this.selectedSource = detail;
      this.showUninstallModal = true;
    },
    confirmUnistall() {
      if (this.busy) return;
      this.busy = true;
      this.$portalApi
        .delete(
          this.apiRoutes.portal.integrations.source.disconnect(
            this.$route.params.integration_id,
            this.selectedSource.id
          )
        )
        .then(({ data }) => {
          this.notifyUserMessage({ message: data.message });
          this.showUninstallModal = false;
          this.getIntegrationSourceList();
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    initSubscriptionModal(message) {
      this.upgradePlanMessage = message;
      this.openSubscriptionModal = true;
    },
    getUpgradePlanText() {
      return `${
        this.showTrialButton ? "Start your team's free trial" : "Upgrade now"
      }
          to enable slack integration and much more.`;
    },
    startFreeTrial() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(START_FREE_TRIAL, { plan_id: "V3GoPO2xXy" })
        .then((response) => {
          this.notifyUserMessage({ message: response.message });
          this.$store
            .dispatch(GET_USER_ROLES)
            .then(() => {
              this.busy = false;
              if (this.selectedIntegration.name === "Slack") {
                this.generateSlackAuthLink();
              } else if (this.selectedIntegration.name === "Microsoft Teams") {
                this.microsoftTeamModal = true;
              } else if (this.selectedIntegration.id === "Vg7oW5YxKv") {
                this.jiraConnectModal = true;
              }
            })
            .catch((err) => {
              this.notifyErrorMessage(err.message);
            });
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    getIntegrationSourceList() {
      this.microsoftTeamModal = false;
      this.jiraConnectModal = false;
      if (this.loading) return;
      this.loading = true;
      this.$portalApi
        .get(
          `${this.apiRoutes?.portal?.integrations?.source?.sourceList(
            this.$route.params.integration_id
          )}`
        )
        .then((response) => {
          this.selectedSource = { ...response?.data[0] };
          this.sourceList = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    openEditSource(detail) {
      this.selectedSource = detail;
      if (this.selectedIntegration.name === "Slack") {
        this.slackInfoPopup = true;
      } else if (this.selectedIntegration.name === "Microsoft Teams") {
        this.microsoftTeamModal = true;
      } else {
        this.jiraConnectModal = true;
      }
    },
    deleteChannel(detail) {
      this.selectedWorkspace = detail;
      this.showChannelDeleteConfirm = true;
    },
    addChannelInfo(detail) {
      this.selectedSource = detail;
      this.showCreateChannelForm = true;
    },
    deleteChannelConfirm(detail) {
      if (this.busy) return;
      this.busy = true;
      this.$portalApi
        .delete(
          `${this.apiRoutes?.portal?.integrations?.slack?.channel?.delete(
            this.selectedChannel?.id
          )}`
        )
        .then((response) => {
          this.notifyErrorMessage({ message: response.message });
          this.busy = false;
          this.selectedChannel = null;
          this.showChannelDeleteConfirm = false;
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    afterChannelSuccess() {
      this.showCreateChannelForm = false;
    },
    generateSlackAuthLink() {
      if (this.slackLoading) return;
      this.slackLoading = true;
      this.$portalApi
        .get(
          this.apiRoutes.portal.integrations.source.generateAuth(
            this.$route.params.integration_id
          )
        )
        .then(({ data }) => {
          this.confirmationModal = false;
          this.slackInfoPopup = false;
          window.open(data.url, "_self");
          this.$nextTick(() => {
            this.slackLoading = false;
          });
        })
        .catch((err) => {
          this.slackLoading = false;
          if (err && err.status === 402) {
            this.upgradePlanMessage = err.message;
            this.openSubscriptionModal = true;
          } else {
            this.notifyErrorMessage(err.message);
          }
        });
    },
  },
};
</script>
<style lang="scss">
.activeCard {
  background: linear-gradient(
      0deg,
      rgba(64, 98, 215, 0.08) 0%,
      rgba(64, 98, 215, 0.08) 100%
    ),
    #fff !important;
  border-color: $blue !important;
}
</style>
