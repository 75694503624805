<template>
  <div>
    <v-row flat>
      <v-col cols="12">
        <v-row>
          <v-col class="darkGrey--text">
            <div>
              <div class="semi-bold bodyFont">
                {{ titleMap[selectedIntegration?.name] }}s
              </div>
              <div class="smallFont">
                Each app can be connected to one
                {{ titleMap[selectedIntegration?.name].toLowerCase() }}.
              </div>
            </div>
          </v-col>

          <v-col v-if="canManageTeam" class="text-right">
            <v-btn
              class="text-transform-none"
              depressed
              color="primary"
              @click="openAddChannelForm"
              :outlined="$vuetify.theme.dark"
            >
              Add {{ titleMap[selectedIntegration?.name] }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col v-if="loading" cols="12">
                <v-row>
                  <v-col v-for="i in 5" :key="i" cols="12">
                    <v-skeleton-loader
                      type="card, list-item"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="!loading && !subSourceList.length" cols="12">
                <no-data
                  :first-text="`No ${
                    titleMap[selectedIntegration?.name]
                  }s  found`"
                ></no-data>
              </v-col>
              <template v-if="subSourceList.length && !loading">
                <v-col cols="12">
                  <DatatableWrapper :loading="loading">
                    <v-data-table
                      :headers="headers"
                      :items="subSourceList"
                      hide-default-header
                      hide-default-footer
                      mobile-breakpoint="0"
                    >
                      <template #item.name="{ item }">
                        <div class="py-4">
                          <div class="bodyFont semi-bold">
                            {{ item.name }}
                          </div>
                          <div class="smallFont">{{ item.uid }}</div>
                        </div>
                      </template>

                      <template #item.app="{ item }">
                        <div>
                          <v-btn icon @click.stop="openAppDrawer(item)">
                            <span class="bodyFont primary--text semi-bold">
                              {{ item?.stats?.apps || "0" }}
                            </span>
                          </v-btn>
                          <div
                            @click.stop="openAppDrawer(item)"
                            class="smallFont cursor-pointer"
                          >
                            {{ item?.stats?.apps == 1 ? "App" : "Apps" }}
                          </div>
                        </div>
                      </template>
                      <template #item.user="{ item }">
                        <user-avatar :user-detail="item.user"></user-avatar>
                      </template>
                      <template #item.status="{ item }">
                        <v-tooltip v-if="item.is_default" bottom>
                          <template v-slot:activator="{ on }">
                            <v-chip
                              v-on="on"
                              small
                              outlined
                              color="primary"
                              v-text="'Team'"
                            ></v-chip>
                          </template>
                          <div>Alerts related to team</div>
                        </v-tooltip>
                      </template>
                      <template #item.action="{ item }">
                        <div v-if="canManageTeam">
                          <action-dropdown id="membersActionDropdown">
                            <template #list>
                              <v-list-item
                                v-if="
                                  item.type === 'alert' &&
                                  !item.is_default &&
                                  canManageTeam
                                "
                                :disabled="busy"
                                @click="makeTeamDefault(item)"
                              >
                                <v-icon small>mdi-application</v-icon>
                                Make Team Default
                              </v-list-item>

                              <v-list-item @click="openAppDrawer(item)">
                                <v-icon left small>apps</v-icon>
                                Manage Apps
                              </v-list-item>

                              <!-- <v-list-item @click="editChannelInfo(item)">
                                  <v-icon small>edit</v-icon>
                                  Edit
                                </v-list-item> -->
                              <v-list-item @click="deleteChannel(item)">
                                <v-icon small>cancel</v-icon>
                                Remove
                              </v-list-item>
                            </template>
                          </action-dropdown>
                        </div>
                      </template>
                    </v-data-table>
                  </DatatableWrapper>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <Modal v-model="showCreateChannelForm">
      <template #card>
        <add-slack-channel-form
          v-if="showCreateChannelForm"
          :selected-source="selectedSource"
          @close="showCreateChannelForm = false"
          @sucess="afterChannelSuccess"
        ></add-slack-channel-form>
      </template>
    </Modal>
    <Modal v-model="showCreateJiraProjectForm">
      <template #card>
        <jira-create-project-form
          v-if="showCreateJiraProjectForm"
          :selected-source="selectedSource"
          @close="showCreateJiraProjectForm = false"
          @sucess="afterChannelSuccess"
        ></jira-create-project-form>
      </template>
    </Modal>
    <Modal v-model="showSubSourceDeleteConfirm">
      <template #card>
        <v-card v-if="showSubSourceDeleteConfirm">
          <v-card-title> Remove {{ selectedSubSource.name }} </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="font-16">
              <v-col cols="12">
                Heads up! You're about to remove
                <b id="selectedName">{{ selectedSubSource.name }}</b>
                from <b>{{ selectedSource.name }}</b
                >, which will also remove it from for all connected apps.
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="showSubSourceDeleteConfirm = false"
              text
              class="text-transform-none"
              color="primary"
            >
              Cancel
            </v-btn>
            <v-btn
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="error"
              @click="deleteChannelConfirm"
            >
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <SidePanel v-model="showAppInfo">
      <AppsInfo
        v-if="showAppInfo"
        from-integration
        :selected-sub-source="selectedSubSource"
        :integration-sub-id="selectedSubSource.id"
        :integrations-endpoint="integrationEndpoint"
        @close="showAppInfo = false"
        @success="addAppCountToSubSource"
        @delete="removeAppCountToSubSource"
      />
    </SidePanel>
  </div>
</template>
<script>
import AddSlackChannelForm from "@/view/components/Team/Integrations/SlackAddChannelForm.vue";
import JiraCreateProjectForm from "@/view/components/Team/Integrations/JiraCreateProjectForm.vue";
import AppsInfo from "@/view/components/Team/AppsInfo";
import UserAvatar from "@/view/components/Common/UserAvatar";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";
export default {
  props: {
    selectedIntegration: {
      type: Object,
      default() {
        return null;
      },
    },
    selectedSource: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddSlackChannelForm,
    JiraCreateProjectForm,
    AppsInfo,
    DatatableWrapper,
    UserAvatar,
  },
  data() {
    return {
      loading: false,
      busy: false,
      showCreateChannelForm: false,
      showCreateJiraProjectForm: false,
      showSubSourceDeleteConfirm: false,
      showAppInfo: false,
      integrationEndpoint: "",
      selectedSubSource: null,
      titleMap: {
        Slack: "Channel",
        "Microsoft Teams": "Channel",
        JIRA: "Project",
      },
      headers: [
        {
          text: "Name",
          value: "name",
        },

        {
          text: "Apps",
          value: "app",
        },
        {
          text: "User",
          value: "user",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Action",
          align: "right",
          value: "action",
        },
      ],
      subSourceList: [
        {
          name: "Car wash",
          id: 2,
          isTeamDefault: false,
          stats: {
            channel: 5,
            apps: 4,
          },
        },
        {
          name: "Delivary",
          id: 44545,
          isTeamDefault: true,
          stats: {
            channel: 5,
            apps: 4,
          },
        },
      ],
    };
  },
  created() {
    this.getIntegrationSubSourceList();
  },
  methods: {
    getIntegrationSubSourceList() {
      if (this.loading) return;
      this.loading = true;
      this.$portalApi
        .get(
          `${this.apiRoutes?.portal?.integrations?.sourceSub?.subList(
            this.$route.params.integration_id,
            this.selectedSource.id
          )}`
        )
        .then((response) => {
          this.subSourceList = response.data;
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    makeTeamDefault(item) {
      if (this.busy) return;
      this.busy = true;
      this.$portalApi
        .post(
          this.apiRoutes.portal.integrations.sourceSub.teamDefault(
            this.$route.params.integration_id,
            this.selectedSource.id,
            item.id
          )
        )
        .then(({ data }) => {
          this.busy = false;
          this.getIntegrationSubSourceList();
          this.notifyUserMessage({ message: data.message });
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    openAddChannelForm() {
      this.selectedSubSource = null;
      if (this.selectedIntegration?.name === "JIRA") {
        this.showCreateJiraProjectForm = true;
      } else {
        this.showCreateChannelForm = true;
      }
    },
    openAppDrawer(detail) {
      this.integrationEndpoint =
        this.apiRoutes.portal.integrations.sourceSubApp.addApp(
          this.$route.params.integration_id,
          this.selectedSource?.id,
          detail.id
        );
      this.selectedSubSource = detail;
      this.showAppInfo = true;
    },
    addAppCountToSubSource() {
      this.getIntegrationSubSourceList();
    },
    removeAppCountToSubSource() {
      this.getIntegrationSubSourceList();
    },
    deleteChannel(detail) {
      this.selectedSubSource = detail;
      this.showSubSourceDeleteConfirm = true;
    },
    editChannelInfo(detail) {
      this.selectedSubSource = detail;
      this.showCreateChannelForm = true;
    },
    deleteChannelConfirm(detail) {
      if (this.busy) return;
      this.busy = true;
      this.$portalApi
        .delete(
          this.apiRoutes?.portal?.integrations?.sourceSub?.delete(
            this.$route.params.integration_id,
            this.selectedSource.id,
            this.selectedSubSource?.id
          )
        )
        .then(({ data }) => {
          this.notifyUserMessage({ message: data.message });
          this.showSubSourceDeleteConfirm = false;
          this.getIntegrationSubSourceList();
          this.selectedSubSource = null;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    afterChannelSuccess() {
      this.showCreateJiraProjectForm = false;
      this.showCreateChannelForm = false;
      this.getIntegrationSubSourceList();
    },
  },
};
</script>
