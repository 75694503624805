var render = function render(){
  var _vm$integrationDetail, _vm$integrationDetail2, _vm$integrationDetail3, _vm$integrationDetail4, _vm$integrationDetail5, _vm$integrationDetail6, _vm$integrationDetail7, _vm$integrationDetail8, _vm$integrationDetail9, _vm$integrationDetail10, _vm$integrationDetail11, _vm$integrationDetail12, _vm$integrationDetail13;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.integrationDetailErrorMessage ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": _vm.integrationDetailErrorMessage
    }
  })], 1)], 1) : _c('v-row', [_c('v-col', {
    staticClass: "smallFont darkGrey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("Team ")]), _vm._v("/ "), _c('span', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'team-integrations'
        });
      }
    }
  }, [_vm._v("Integrations")]), _vm._v(" / "), _c('span', {
    staticClass: "info--text"
  }, [_vm._v(" " + _vm._s((_vm$integrationDetail = _vm.integrationDetail) === null || _vm$integrationDetail === void 0 ? void 0 : _vm$integrationDetail.name))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.gotoLastPage
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-chevron-left')
    }
  }), _vm._v(" Go Back ")], 1)], 1), _vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "card,list"
    }
  })], 1)], 1)], 1) : !_vm.loading && !_vm.isEmpty(_vm.integrationDetail) ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.integrationDetail ? _c('v-card', {
    staticClass: "pa-3",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {}, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.integrationDetail.image ? _c('v-img', {
    staticClass: "mb-2",
    attrs: {
      "height": "40",
      "contain": "",
      "width": "40",
      "src": "/media/integrations/".concat(_vm.integrationDetail.image)
    }
  }) : _vm._e()], 1), _c('v-col', [_c('div', [_c('span', {
    staticClass: "semi-bold title"
  }, [_vm._v(" " + _vm._s((_vm$integrationDetail2 = _vm.integrationDetail) === null || _vm$integrationDetail2 === void 0 ? void 0 : _vm$integrationDetail2.name) + " ")]), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.showIntegrationHelp(_vm.integrationDetail);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-help-circle-outline')
    }
  })], 1)], 1), _c('div', {
    staticClass: "smallFont"
  }, [_vm._v(" " + _vm._s(((_vm$integrationDetail3 = _vm.integrationDetail) === null || _vm$integrationDetail3 === void 0 ? void 0 : _vm$integrationDetail3.summary) || "Connect this integrations") + " ")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [(_vm$integrationDetail4 = _vm.integrationDetail) !== null && _vm$integrationDetail4 !== void 0 && (_vm$integrationDetail5 = _vm$integrationDetail4.meta) !== null && _vm$integrationDetail5 !== void 0 && (_vm$integrationDetail6 = _vm$integrationDetail5.chips) !== null && _vm$integrationDetail6 !== void 0 && _vm$integrationDetail6.length ? _vm._l((_vm$integrationDetail7 = _vm.integrationDetail) === null || _vm$integrationDetail7 === void 0 ? void 0 : (_vm$integrationDetail8 = _vm$integrationDetail7.meta) === null || _vm$integrationDetail8 === void 0 ? void 0 : _vm$integrationDetail8.chips, function (chip, cindex) {
    return _c('v-chip', {
      key: cindex,
      staticClass: "mr-1"
    }, [_vm._v(" " + _vm._s(chip) + " ")]);
  }) : _vm._e(), (_vm$integrationDetail9 = _vm.integrationDetail) !== null && _vm$integrationDetail9 !== void 0 && (_vm$integrationDetail10 = _vm$integrationDetail9.meta) !== null && _vm$integrationDetail10 !== void 0 && (_vm$integrationDetail11 = _vm$integrationDetail10.labels) !== null && _vm$integrationDetail11 !== void 0 && _vm$integrationDetail11.length ? _vm._l((_vm$integrationDetail12 = _vm.integrationDetail) === null || _vm$integrationDetail12 === void 0 ? void 0 : (_vm$integrationDetail13 = _vm$integrationDetail12.meta) === null || _vm$integrationDetail13 === void 0 ? void 0 : _vm$integrationDetail13.labels, function (label, lindex) {
    return _c('v-chip', {
      key: lindex,
      staticClass: "mr-1",
      attrs: {
        "color": label.type
      }
    }, [_vm._v(" " + _vm._s(label.text) + " ")]);
  }) : _vm._e()], 2)], 1)], 1) : _vm._e()], 1) : _vm._e(), !_vm.integrationDetailErrorMessage && !_vm.isEmpty(_vm.integrationDetail) && !_vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('integration-source', {
    attrs: {
      "selected-integration": _vm.integrationDetail
    }
  })], 1)], 1)], 1) : _vm._e()], 1), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', [_vm._v(" Congratulations 🎉 ")]), _c('v-card-text', [_c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "color": "primary",
            "size": "24"
          }
        })], 1), _c('v-col', {
          staticClass: "text-center text-h6",
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" We are processing the integration "), _c('br'), _vm._v(" and connecting with your slack account. ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.confirmationPopup,
      callback: function callback($$v) {
        _vm.confirmationPopup = $$v;
      },
      expression: "confirmationPopup"
    }
  }), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    on: {
      "close": function close($event) {
        _vm.integrationHelpModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', {
          staticClass: "pa-3"
        }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
          staticClass: "bodyFont",
          attrs: {
            "cols": "12"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.integrationHelpDescription)
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.integrationHelpModal,
      callback: function callback($$v) {
        _vm.integrationHelpModal = $$v;
      },
      expression: "integrationHelpModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }