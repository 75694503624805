<template>
  <v-card>
    <v-card-title>
      {{ selectedChannel ? "Update" : "Add" }}
      {{ titleMap[selectedIntegration?.name] }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="bodyFont semi-bold" cols="12">
          <div>
            <div>
              {{ selectedSource?.name }}
            </div>
          </div>
        </v-col>
        <v-col
          v-if="['Microsoft Teams', 'Slack'].includes(selectedIntegration.name)"
          cols="12"
        >
          <div class="pointer-cursor" @click="showZoomedImage('microsoftTeam')">
            <v-carousel
              v-if="selectedIntegration.name === 'Microsoft Teams'"
              height="250"
              progress="primary"
              hide-delimiters
            >
              <v-carousel-item
                v-for="i in 4"
                :key="i"
                :src="`/media/integrations/microsoft_teams/${i + 1}.png`"
                contain
                height="250"
              ></v-carousel-item>
            </v-carousel>
          </div>
          <div class="pointer-cursor" @click="showZoomedImage('slack')">
            <v-carousel
              v-if="selectedIntegration.name === 'Slack'"
              height="250"
              progress="primary"
              hide-delimiters
            >
              <v-carousel-item
                v-for="i in 4"
                :key="i"
                :src="`/media/integrations/slack/${i}.png`"
                contain
                height="250"
              ></v-carousel-item>
            </v-carousel>
          </div>
        </v-col>

        <v-col v-if="selectedIntegration.name !== 'Microsoft Teams'" cols="12">
          <v-text-field
            filled
            v-model="formValues.uid"
            v-validate="'required'"
            v-bind="
              veeValidate(
                'Channel ID',
                `${titleMap[selectedIntegration?.name]} ID`
              )
            "
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            v-model="formValues.name"
            v-validate="'required'"
            v-bind="
              veeValidate(
                'Channel Name',
                `${titleMap[selectedIntegration?.name]} name`
              )
            "
          ></v-text-field>
        </v-col>

        <v-col v-if="selectedIntegration?.name === 'Microsoft Teams'" cols="12">
          <v-text-field
            filled
            v-model="microsoftTeamWebhook"
            v-validate="'required|url'"
            v-bind="veeValidate('Url', 'Channel Incoming Webhook URL')"
          >
            <template v-slot:append-outer>
              <v-icon @click="showWebhookInfoModal = true"
                >mdi-help-circle-outline</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="text-transform-none"
        text
        :disabled="busy"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        class="text-transform-none"
        :outlined="$vuetify.theme.dark"
        :loading="busy"
        @click="confirmSubmit"
      >
        <span v-if="fromWorkspace">Add</span>
        <span v-else>
          {{ selectedChannel ? "Update" : "Add" }}
        </span>
      </v-btn>
    </v-card-actions>
    <Modal v-model="showWebhookInfoModal" closeable>
      <template #card>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="12">
              Follow these steps to connect your channel with your team:
              <br />
              <br />
              <ul>
                <li>From your channel menu, open <b>Connectors</b></li>
                <li>
                  Search for <b>Incoming Webhook</b> and click <b>Configure</b>
                </li>
                <li>Name of Webhook: <b>TestApp.io</b></li>
                <li>
                  Image:
                  <a
                    href="https://assets.testapp.io/logo/icon/512.png"
                    target="_blank"
                    class="text-decoration-underline"
                    ><b>Click here to download</b></a
                  >
                </li>
                <li>Click on <b>Create</b> and copy the <b>URL</b></li>
              </ul>
              <br />
              You can learn more from
              <a
                href="https://blog.testapp.io/connect-microsoft-teams/"
                target="_blank"
              >
                this article</a
              >
            </v-col>
          </v-row>
        </v-card>
      </template>
    </Modal>
    <Modal closeable v-model="showZoomedImageModal">
      <template #card>
        <v-card class="pa-3">
          <v-row>
            <v-col cols="12">
              <v-carousel
                v-if="selectedImage === 'microsoftTeam'"
                height="250"
                hide-delimiters
              >
                <v-carousel-item
                  v-for="i in 4"
                  :key="i"
                  width="100%"
                  height="100%"
                  contain
                  :src="`/media/integrations/microsoft_teams/${i + 1}.png`"
                ></v-carousel-item>
              </v-carousel>
              <v-carousel v-if="selectedImage === 'slack'" hide-delimiters>
                <v-carousel-item
                  v-for="i in 2"
                  :key="i"
                  contain
                  height="100%"
                  width="100%"
                  :src="`/media/integrations/slack/${i}.png`"
                ></v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </Modal>
  </v-card>
</template>
<script>
import veeValidate from "@/mixins/veeValidate";
import { mapGetters } from "vuex";
export default {
  name: "SlackAddChannelForm",
  mixins: [veeValidate],
  props: {
    selectedChannel: {
      type: Object,
      default() {
        return null;
      },
    },
    selectedSource: {
      type: Object,
      required: true,
    },
    fromWorkspace: {
      type: Boolean,
      default: false,
    },
    assigneList: [],
    reporterList: [],
    issueType: [],
    subSourceList: [],
    workspaceDetail: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      selectedImage: "",
      showZoomedImageModal: false,
      showWebhookInfoModal: false,
      formValues: {
        uid: "",
        name: "",
        info: {
          assignee: "",
          reporter: "",
          issue: "",
        },
      },
      microsoftTeamWebhook: "",
      busy: false,
      titleMap: {
        Slack: "Channel",
        "Microsoft Teams": "Channel",
        JIRA: "Project",
      },
      workSpaceList: [
        {
          name: "Car wash",
          id: 2,
          stats: {
            channel: 5,
            apps: 4,
          },
        },
        {
          name: "Delivary",
          id: 44545,
          stats: {
            channel: 5,
            apps: 4,
          },
        },
      ],
    };
  },

  // computed: {
  //   ...map,
  // },
  mounted() {},
  computed: {
    ...mapGetters({
      selectedIntegration: "getSelectedIntegration",
    }),
  },
  watch: {},
  created() {},
  methods: {
    confirmSubmit() {
      if (this.selectedChannel?.id) {
        this.updateChannel();
      } else {
        this.addNewChannel();
      }
    },
    showZoomedImage(type) {
      this.selectedImage = type;
      this.showZoomedImageModal = true;
    },
    async addNewChannel() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        let dataToPost = {};
        if (this.selectedIntegration?.name === "Slack") {
          dataToPost = { ...this.formValues };
          delete dataToPost.info;
        }
        if (this.selectedIntegration?.name === "JIRA") {
          dataToPost = {
            ...this.formValues,
          };
        }
        if (this.selectedIntegration?.name === "Microsoft Teams") {
          this.formValues.info = { webhook_url: this.microsoftTeamWebhook };
          dataToPost = {
            ...this.formValues,
          };
        }
        this.$portalApi
          .post(
            this.apiRoutes.portal.integrations.sourceSub.create.slack(
              this.$route.params.integration_id,
              this.selectedSource.id
            ),
            dataToPost
          )
          .then(({ data }) => {
            this.busy = false;
            this.notifyUserMessage({ message: data.message });
            this.$emit("sucess");
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
            this.busy = false;
          });
      }
    },
    updateChannel() {
      if (this.busy) return;
      this.busy = true;
      let dataToPost = {};
      if (this.selectedIntegration?.name === "Slack") {
        dataToPost = { ...this.formValues };
      }
      if (this.selectedIntegration?.name === "JIRA") {
        dataToPost = {
          ...this.formValues,
          info: { ...this.selectedSource?.info },
        };
      }
      this.$portalApi
        .put(
          this.apiRoutes.portal.integrations.sourceSub.update(
            this.$route.params.integration_id,
            this.selectedSource.id,
            this.selectedChannel.id
          ),
          dataToPost
        )
        .then(({ data }) => {
          this.busy = false;
          this.notifyUserMessage({ message: data.message });
          this.$emit("sucess");
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
  },
};
</script>
